import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/context/privateRoute"
import Analysis from "../../components/app/analysis"
import AppLayout from "../../components/app/app-components/layout"

const AppAnalysis = ({ path }) => {
  return (
    <AppLayout title="Analysis" path={path}>
      <Router>
        <PrivateRoute path="/app/analysis" component={Analysis} />
      </Router>
    </AppLayout>
  )
}

export default AppAnalysis
