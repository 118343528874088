import React from "react"

export const StatCardSmall = ({ title, value, change }) => {
  return (
    <div className="flex flex-col justify-around w-20 h-20 p-2 bg-white shadow rounded-xl">
      <p className="text-xs ">{title}</p>
      <p
        className={`text-2xl font-base ${
          title === "Incorrect" && "text-red-500"
        }`}
      >
        {value}
      </p>
      {change && (
        <p className="text-sm leading-tight text-red-500 font-base ">
          {change ? `${change}%` : ""}
        </p>
      )}
    </div>
  )
}
