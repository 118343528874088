import React, { useState } from "react"
import PaperPerformanceCard from "./PaperPerformanceCard"
import { WeakSubjectCard } from "./WeakSubjectCard"
import { SWOTCard } from "./SWOTCard"
import { OverallScoreCard } from "./OverallScoreCard"
import { AcadmiScoreCard } from "./AcadmiScoreCard"
import { StatCardBig } from "./StatCardBig"
import { StatCardSmall } from "./StatCardSmall"
import BlankCard from "../app-components/blankCard"
import { useQuery } from "react-query"
import { getAllAttempts, getQuestionAnswers } from "../../context/graph"
import Loader from "../../utils/Loader"
import { useAuth } from "../../context/auth"
import jmespath from "jmespath"
import _ from "lodash"
import { VscCircleFilled } from "react-icons/vsc"
import { motion } from "framer-motion"
import { myAnimations } from "../../context/framer-animations"

const Analysis = () => {
  const { currentUser } = useAuth()
  const [QIDArray, setQIDArray] = useState([])
  const [Responses, setResponses] = useState([])
  const [Answers, setAnswers] = useState([])

  const { isLoading: isLoadingAttempts, isSuccess: isSuccessAttempts } =
    useQuery(
      [["getAllAttempts"], currentUser?.email],
      () => getAllAttempts(currentUser?.email),
      {
        onSuccess: attempts => {
          if (attempts?.length > 0) {
            setQIDArray(attempts[0]?.responses[0]?.quiz)
            setResponses(attempts[0]?.responses[0])
          } else {
            console.log(null)
          }
        },
        enabled: !!currentUser?.email,
      }
    )
  const { isLoading, isError, isSuccess } = useQuery(
    ["getQuestionAnswers"],
    () => getQuestionAnswers(QIDArray),
    {
      enabled: QIDArray?.length > 0,
      // refetchInterval: false,
      // staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const answers = jmespath.search(
          data,
          "[].{questionId:questionId, correct: options[?isCorrect].optionId}"
        )
        setAnswers(answers)
      },
    }
  )

  if (isLoading && isLoadingAttempts) return <Loader />

  const selectedResponses = jmespath.search(
    Responses.questions,
    "[].{questionId:questionId, selectedOption: options[?selected].optionId}"
  )
  const timeTakenArray = jmespath.search(Responses.questions, "[].timeTaken")
  const timeTaken = _.sum(timeTakenArray)
  function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s
  }
  const timeString = fmtMSS(timeTaken)

  let correctQues = []
  let incorrectQues = []
  if (Answers?.length > 0 && selectedResponses?.length > 0) {
    selectedResponses?.forEach(question => {
      const getquestion = _.filter(Answers, { questionId: question.questionId })
      if (
        getquestion[0].correct[0] === question.selectedOption[0] &&
        question.selectedOption.length > 0
      ) {
        correctQues.push(question)
      } else if (question.selectedOption.length > 0) {
        incorrectQues.push(question)
      }
    })
  }
  const total = Answers.length
  const correct = correctQues.length
  const incorrect = incorrectQues.length
  const totalAttempted = correct + incorrect
  const accuracy = Math.round((100 * correct) / totalAttempted)
  const paperName = Responses?.quizId?.substring(0, 5)
  const sectionName = Responses?.name

  // console.log("Data", Responses, paperName)

  return (
    <motion.div {...myAnimations.fadeIn} className="m-5 space-y-4">
      <div className="mb-4 font-base">Previous Attempt Results</div>

      {!isLoading && !isLoadingAttempts ? (
        <>
          <div className="flex item-center">
            <p className="text-sm">{paperName}</p>
            <VscCircleFilled className="w-4 mx-2 text-gray-500" />
            <p className="text-sm">{sectionName}</p>
            <VscCircleFilled className="w-4 mx-2 text-gray-500" />
            <p className="text-sm">{timeString}</p>
          </div>
          <div className="flex space-x-2 h-44">
            <StatCardBig title="Accuracy" value={accuracy + " %"} />
            <div className="grid items-center justify-center w-1/2 grid-cols-2 gap-2">
              <StatCardSmall title="Total" value={total} />
              <StatCardSmall title="Attempted" value={totalAttempted} />
              <StatCardSmall title="Correct" value={correct} />
              <StatCardSmall title="Incorrect" value={incorrect} />
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
      <div className="pt-8 mb-4 font-base">Your overall performance</div>
      <BlankCard text="Analysis will be available after a few attempts" />
      {/* <AcadmiScoreCard />
      <div className="flex space-x-2">
        <OverallScoreCard />
        <OverallScoreCard />
      </div> */}
      <div className="pt-8 mb-4 font-base">Strengths</div>
      <BlankCard text="Analysis will be available after a few attempts" />

      {/* <SWOTCard />
      <SWOTCard /> */}
      <div className="pt-8 mb-4 font-base">Need Improvement</div>
      <BlankCard text="Analysis will be available after a few attempts" />

      {/* <SWOTCard />
      <SWOTCard /> */}
      <div className="pt-8 mb-4 font-base">Weak Subjects</div>
      <BlankCard text="Analysis will be available after a few attempts" />

      {/* <WeakSubjectCard /> */}
      <div className="pt-8 mb-4 font-base">Paperwise Performace</div>
      <BlankCard text="Analysis will be available after a few attempts" />

      {/* <PaperPerformanceCard />
      <PaperPerformanceCard /> */}
      <div className="pt-8 mb-4"></div>
    </motion.div>
  )
}

export default Analysis
