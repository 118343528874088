import React from "react"

export const StatCardBig = ({ title, value, change }) => {
  return (
    <div className="flex flex-col justify-around w-1/2 px-4 bg-white shadow rounded-xl">
      <p className="">{title}</p>
      <p className="text-6xl font-base ">{value}</p>
      <p className="leading-tight text-red-500 font-base ">{change}</p>
    </div>
  )
}
